export let DATA_TYPE: { [key: string]: string } = {
  transaction_data: 'Transaction Data',
  other_data: 'Other Data',
  lookup_cardbin_country_from_merchants: 'BINs (Merchants)',
  lookup_cardbins_extracted_from_Python_package: 'BINs (Package)',
  lookup_Ccass_table: 'Acquirer Combos',
  lookup_merchant_list: 'Merchant List',
  lookup_time_filters: 'Time Filters',
  lookup_iso_3166_1_alpha_2: 'ISO 3166 1alpha2',
  lookup_country_name_code: 'Country Codes',
  lookup_footfall_mapping: 'Footfall Mapping',
  lookup_ISOCalendar: 'ISO Calendar',
  lookup_timezone_lookup_table: 'Time Zones',
  lookup_currencyname_adjustments_to_monthly_FX: 'Currency Names',
  lookup_card_country_region: 'Country Regions',
  events_data: 'Events Data',
  hotel_data: 'Hotel Data',
  travel_data: 'Travel Data',
  footfall_historic_data: 'Footfall Data',
  weather_data_rainfall: 'Weather (Rainfall) Data',
  weather_data_temperature: 'Weather (Temperature) Data',
};

export const addDataType = (key: string, value: string) => {
  return (DATA_TYPE = {
    ...DATA_TYPE,
    [key]: value,
  });
};

export const TRANSFORMATIONS: { [key: string]: string } = {
  add_value_based_on_dict: 'Add column values based on mapping',
  add_column_concatenate: 'Add concatenated columns',
  add_index_column: 'Add index column',
  add_or_overwrite_column_with_value: 'Add or overwrite column with value',
  change_target_value_based_on_index:
    'Change column to target value based on index',
  change_target_value_when_source_contains_selected_string:
    'Change column to target value if another column contains selected string',
  change_target_value_when_source_value_is_in_list:
    'Change column to target value if source value is in list',
  change_target_value_when_source_value_fit_condition:
    'Change column to target value if source value satisfies the condition',
  change_data_type: 'Change data type',
  convert_column_value_capitalize:
    'Convert column case: Change to initial capital letters',
  convert_column_value_to_lowercase: 'Convert column case: Lowercase',
  convert_column_value_to_uppercase: 'Convert column case: Uppercase',
  split_string_column_to_list_column:
    'Convert string values in column to list values',
  add_column_copy_to_target: 'Copy column to existing column',
  add_year_flag: 'Datetime: Add current/prior year flag column',
  add_column_convert_datetime_format:
    'Datetime: Convert column to datetime format',
  add_timestamp_column_from_column:
    'Datetime: Convert column to timestamp data type',
  day_of_the_week: 'Datetime: Extract day of the week',
  filter_for_daterange: 'Datetime: Filter for date range',
  filter_out_daterange: 'Datetime: Filter out date range',
  add_column_unify_timezone: 'Datetime: Unify column timezone values',
  drop_duplicate_rows: 'Drop duplicate rows',
  group_drop_column: 'Drop multiple columns',
  drop_null_columns: 'Drop null columns',
  drop_column: 'Drop one column',
  drop_rows_if_column_contains_selected_string:
    'Drop rows if column contains selected string',
  drop_rows_with_column_value_in_list: 'Drop rows if column value in list',
  drop_rows_with_less_than_n_data_entries:
    'Drop rows with less than N data entries that are not null',
  drop_rows_with_null_value: 'Drop rows with null value in a column',
  drop_rows_with_exact_value_in_column:
    'Drop rows with specified value in column',
  drop_dataframe_head_or_tail_rows: 'Drop several head or tail rows',
  column_fillna: 'Fill null values',
  dataframe_groupby_and_agg: 'Groupby and aggregate',
  join_lookup_table: 'Join with a lookup table',
  keep_rows_if_column_contains_selected_string:
    'Keep rows if column contains selected string',
  keep_rows_with_column_value_in_list: 'Keep rows if column value in a list',
  keep_rows_with_exact_value_in_column:
    'Keep rows with a specified value in a column',
  keep_rows_with_null_value: 'Keep rows with null value',
  keep_these_columns_only: 'Keep specified columns only',
  mapping_merchant_id: 'Map rows to Merchant IDs',
  YoY_calculation: 'Numeric operation: Calculate YoY growth',
  flip_sign_column_value: 'Numeric operation: Change sign of column',
  FX_transformation: 'Numeric operation: Convert currency',
  divide_by_column: 'Numeric operation: Divide column by column',
  add_or_update_column_divide_source_by_value:
    'Numeric operation: Divide column by value',
  column_operation: 'Numeric operation: Element-wise column operation',
  keep_rows_based_on_number_threshold:
    'Numeric operation: Keep rows based on value threshold',
  round_column_value: 'Numeric operation: Round column values',
  search_regex_in_column_and_fill:
    'Regex: Fill if target column if source column satisfies regular expression',
  search_regex_and_filter:
    'Regex: Filter in or out if column values satisfy regular expression',
  group_search_regex_in_source_column_replace_target_column:
    'Regex: Replace values in all columns that satisfy regular expression',
  search_regex_in_source_column_replace_target_column:
    'Regex: Replace values in one column if source column satisfies regular expression',
  group_rename_columns: 'Rename multiple columns',
  rename_column: 'Rename one column',
  replace_column_null_with_another_column:
    'Replace null values with values from another column',
  sort_by_columns: 'Sort by columns',
  sort_list_column: 'Sort list values in column',
  split_column_to_target_columns:
    'Split column into target columns based on separator',
  group_strip_columns: 'Strip whitespace from all column names',
  strip_list_column_value:
    'Strip whitespace from all strings or string elements of lists in column',
  strip_column: 'Strip whitespace from one column name',
  unify_country_names: 'Unify country names in column',
  calculate_timestamp_cols_difference:
    'Datetime: Calculate difference between two timestamp columns',
};

export const VALIDATIONS: { [key: string]: string } = {
  check_column_contain_value_in_all_rows:
    'Check if column contains specified value in all rows',
  check_column_regex: 'Check if column matches regular expression',
  check_duplicates: 'Check that column does not contain duplicates',
  check_if_all_value_in_list_are_in_df:
    'Check if all values in list present in column',
  check_if_value_is_present_in_column: 'Check if value is present in column',
  check_missing_columns: 'Check for missing columns',
  check_no_column_values_fit_condition:
    'Check that no column values satisfy the condition',
  check_number_of_unique_column_value_count:
    'Check on the number of unique values in column',
  validate_with_great_expectations: 'Validate with Great Expectations',
};

export const ANALYSIS_FUNCTIONS: { [key: string]: string } = {
  calc_sales_growth: 'Spend: Sales growth or sales share',
  hourly_sales_reporting_month: 'Spend: Hourly sales',
  calc_atv: 'Spend: ATV',
  calc_atv_growth: 'Spend: ATV growth',
  calc_avg_duration: 'Travel: Average length of travel stay or booking lead time',
  calc_index_by_merchant: 'Spend: ATV index',
  calc_index_by_merchant_category: 'Spend: ATV index',
  calc_share_by_merchant: 'Spend: Sales share by merchant',
  calc_share_growth_by_merchant: 'Spend: Sales share growth by merchant',
  calc_ytd_sales_percent_by_merchant: 'Spend: Sales YTD share by merchant',
  calc_total_dom_vs_intl_spend: 'Spend: Domestic vs international spend',
  monthly_sales_mix: 'Spend: Domestic vs international spend (historical)',
  calc_prop_west_end_sales_for_top_n_countries: 'Spend: Monthly spend for top countries as share of all spend',
  calc_monthly_share_of_spend_for_top_10_countries_to_all_spend: 'Spend: Monthly spend for top countries as share of all spend',
  calc_monthly_share_of_spend_for_top_n_countries_to_int_spend: 'Spend: Monthly spend for top countries as share of international spend',
  select_top_n_country_by_merchant_spend: 'Spend: YTD spend share of top countries',
  calc_intl_spend_share_by_region: 'Spend: International spend share by region',
  calc_intl_sales_percentage_by_merchant: 'Spend: International sales percentage',
  calc_monthly_intl_spend_composition_by_merchant: 'Spend: International sales composition',
  calc_intl_sales_growth_by_merchant: 'Spend: Growth of international sales %',
  calc_monthly_sales_footfall_growth: 'Sales and footfall growth (YoY or vs equivalent pre-pandemic period in 2019, for each of the last 12 months)',
  calc_index_of_sales_to_travellers: 'Index of sales to travellers for the top countries YTD',
  calc_footfall_growth: 'Footfall: Footfall growth',
  calc_daily_footfall: 'Footfall: Daily footfall',
  hourly_footfall_reporting_month: 'Footfall: Hourly footfall',
  calc_passenger_number: 'Travel: Monthly share of passengers',
  calc_travellers_to_london: 'Travel: Share of passengers by country over time',
  calc_share_passengers_by_country: 'Travel: Share of passengers by country over time',
  calc_passenger_growth: 'Travel: Passenger growth',
  calc_yoy_growth_in_travel_bookings_for_top_countries: 'Travel: YoY growth in travel bookings for top countries',
  calc_daily_weather: 'Weather: Daily weather (rainfall and temperature)',
  create_output_eventsummary_table: 'Events: Event list',
  create_output_events_table: 'Events: Event calendar view',
  create_output_events_waterfallchart_table:
    'Events: Daily share of monthly sales for equivalent event days in PY',
  move_hotel_data: 'Hotel: Hotel KPIs',
  calc_customer_growth: 'Spend: All customer growth',
  calc_new_customer_growth: 'Spend: New customer growth',
  calc_repeated_vs_new_customer_split: 'Spend: Repeated vs new customer spend',
  calc_number_of_trips: 'Spend: Average number of trips per customer',
  calc_spend_per_customer: 'Spend: Average spend per customer',
  calc_spend_per_new_customer: 'Spend: Average spend per new customer',
  calc_transaction_growth: 'Spend: Transaction number growth or transaction number share'
};
