import { createSlice } from '@reduxjs/toolkit';
import { DataType, LookUpTableState } from '../types/dataTypes';

const initialState: LookUpTableState = {
  lookup_tables: [
    {
      value: 'lookup_cardbin_country_from_merchants',
      name: 'BINs (Merchants)',
    },
    {
      value: 'lookup_cardbins_extracted_from_Python_package',
      name: 'BINs (Package)',
    },
    { value: 'lookup_Ccass_table', name: 'Acquirer Combos' },
    { value: 'lookup_merchant_list', name: 'Merchant List' },
    { value: 'lookup_time_filters', name: 'Time Filters' },
    { value: 'lookup_iso_3166_1_alpha_2', name: 'ISO 3166 1alpha2' },
    { value: 'lookup_country_name_code', name: 'Country Codes' },
    { value: 'lookup_footfall_mapping', name: 'Footfall Mapping' },
    { value: 'lookup_ISOCalendar', name: 'ISO Calendar' },
    { value: 'lookup_timezone_lookup_table', name: 'Time Zones' },
    { value: 'lookup_card_country_region', name: 'Country Regions' },
    {
      value: 'lookup_currencyname_adjustments_to_monthly_FX',
      name: 'Currency Names',
    },
  ],
};

export const lookupTablesSlice = createSlice({
  name: 'otherDataTypes',
  initialState,
  reducers: {
    addNewLookUpTable: (state, action) => {
      state.lookup_tables = state.lookup_tables.some(
        (table: DataType) => table.value === action.payload.value,
      )
        ? [...state.lookup_tables]
        : [...state.lookup_tables, action.payload];
    },
  },
});

export const { addNewLookUpTable } = lookupTablesSlice.actions;

export default lookupTablesSlice.reducer;
